
import { throttle } from '@/utils/utils';

import { IS_SHOW_ALERT_LAYOUT, mapGettersOfLayout } from '@/store/layout';

import layoutMixin from '@/mixins/layoutMixin';

export default {
	name: 'CaseListFilterContainer',
	mixins: [layoutMixin],
	data() {
		return {
			previousScrollY: 0,
			scrollDirection: 'down',
			translateY: 0,
		};
	},
	computed: {
		...mapGettersOfLayout([IS_SHOW_ALERT_LAYOUT]),
		computedStyles() {
			return {
				transform: `translateY(${this.translateY}px)`,
			};
		},
	},
	mounted() {
		if (this.IS_MOBILE) {
			this.previousScrollY = window.scrollY;
			window.addEventListener('scroll', this.throttledHandleScroll, { passive: true });
		}
	},
	beforeDestroy() {
		window.removeEventListener('scroll', this.throttledHandleScroll);
	},
	methods: {
		throttledHandleScroll: throttle(function () {
			requestAnimationFrame(this.handleScroll);
		}, 100),
		handleScroll() {
			const currentScrollY = window.scrollY;
			if (currentScrollY > this.previousScrollY) {
				this.scrollDirection = 'down';
			} else {
				this.scrollDirection = 'up';
			}
			this.previousScrollY = currentScrollY;
			this.updateTranslateY();
		},
		updateTranslateY() {
			if (this.scrollDirection === 'up') {
				this.translateY = 0;
				return;
			}

			const MINIMUM_TRANSLATE_Y = -44;
			const NEGATIVE_FILTER_MARGIN_BOTTOM = 12;

			let newTranslateY = Math.max(MINIMUM_TRANSLATE_Y, -this.previousScrollY);
			if (newTranslateY > NEGATIVE_FILTER_MARGIN_BOTTOM) {
				newTranslateY = 0;
			}
			this.translateY = newTranslateY;
		},
	},
	components: {},
};
