
import { mapGetters } from 'vuex';

import { SORT_LATEST } from '@/plugins/constants';

import { mapMutationsOfCamp, SET_CAMP_CHARM_ITEM } from '@/store/camp';
import {
	mapMutationsOfCase,
	IS_JOB_SEEKING,
	SET_SORTING_OPTIONS,
	mapGettersOfCase,
	USER_PARTNER_INFO,
	IS_UNIV_AFFILIATE_AVAILABLE,
} from '@/store/case';
import { mapGettersOfLayout, SUB_HEADER_INDEX } from '@/store/layout';
import { mapGettersOfMyPage, GET_PASS_INFO } from '@/store/myPage';
import { mapMutationsOfUserMeta, SET_USER_META_STEP } from '@/store/userMeta';

import CaseListCardSection from '@/components/case/list/main/organisms/CaseListCardSection.vue';
import UserMetaModal from '@/components/common/molecules/userMetaModal/UserMetaModal.vue';

import caseMixin from '@/mixins/caseMixin';
import layoutMixin from '@/mixins/layoutMixin';
import userInfoMixin from '@/mixins/userInfoMixin';
import userMetaModalMixin from '@/mixins/userMetaModalMixin';

export default {
	name: 'CaseList',
	mixins: [layoutMixin, caseMixin, userInfoMixin, userMetaModalMixin],
	async fetch() {
		await this.setEduCharm();
	},
	data() {
		return {
			isUnivAffiliateAvailable: false,
			EDU_TYPE: 'edu',
			PT_TYPE: 'pt',
			showFilterModal: false,
		};
	},
	computed: {
		...mapGetters(['isUser']),
		...mapGettersOfCase([USER_PARTNER_INFO, IS_UNIV_AFFILIATE_AVAILABLE, IS_JOB_SEEKING]),
		...mapGettersOfLayout([SUB_HEADER_INDEX]),
		...mapGettersOfMyPage([GET_PASS_INFO]),
		getProductType() {
			return this.shouldShowClassPtBanner ? this.PT_TYPE : this.EDU_TYPE;
		},
		shouldShowClassPtBanner() {
			// CASE_CATEGORY_GROUP_ID를 쓰지 않아 일단 임시로 false 처리해놓습니다.
			return false;
		},
	},
	watch: {
		async [SUB_HEADER_INDEX]() {
			await this.setSortingOptions();
		},
	},
	created() {
		this.setSortingOptions();
	},
	methods: {
		...mapMutationsOfCamp([SET_CAMP_CHARM_ITEM]),
		...mapMutationsOfUserMeta([SET_USER_META_STEP]),
		...mapMutationsOfCase([SET_SORTING_OPTIONS]),
		setSortingOptions() {
			this[SET_SORTING_OPTIONS]([SORT_LATEST]);
		},
		async getEduCharm() {
			const params = { product_type: this.getProductType };

			try {
				if (params.product_type === this.PT_TYPE) {
					const {
						data: { info, old_url, url },
					} = await this.$api.camp.getEduCharm(params);
					return { ...info, old_url, url };
				} else {
					const {
						data: { info, link },
					} = await this.$api.camp.getEduCharm(params);
					return { ...info, link };
				}
			} catch (error) {
				console.error(error);
				return null;
			}
		},
		async setEduCharm() {
			const data = await this.getEduCharm();

			if (data) {
				this[SET_CAMP_CHARM_ITEM]({ ...data });
			}
		},
	},
	components: {
		CaseListCardSection,
		UserMetaModal,
	},
};
