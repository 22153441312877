
import debounce from 'lodash/debounce';
import { mapGetters } from 'vuex';

import { GTM_COMMUNITY_WHERE } from '@/constants/community/gtm';

import { removeBaseUrl } from '@/utils/utils';

import {
	mapGettersOfCase,
	mapMutationsOfCase,
	GET_IS_AUTHORITY,
	CASE_LIST,
	SET_CASE_LIST,
	IS_FINISH_RELATION_CONTENT,
	CASE_LIST_INFO,
	SET_CASE_LIST_INFO,
	CASE_LIMIT,
	CASE_PAGE,
	CASE_SORTING,
	INFINITE_ID,
	IS_SORT_LATEST,
	IS_CAREER_CHANGE,
	LOAD_CASE_LIST,
	mapActionsOfCase,
	SET_IS_EMPTY_RECOMMENDED_LIST,
	IS_EMPTY_RECOMMENDED_LIST,
	CASE_GTM_CATEGORY,
	GET_IS_FETCHING_CASE_LIST,
	SET_IS_FETCHING_CASE_LIST,
	QUESTION_TYPE_OPTIONS,
	IS_JOB_SEEKING,
	SET_CASE_CATEGORY_JOB,
	CASE_CATEGORY_JOB,
} from '@/store/case';
import { mapGettersOfLayout, SUB_HEADER_INDEX } from '@/store/layout';
import { GET_IS_SHOW_USER_META_MODAL, mapGettersOfUserMeta } from '@/store/userMeta';

import FinishRecommendedCaseBanner from '@/components/case/list/main/atoms/FinishRecommendedCaseBanner.vue';
import CaseListLoginBanner from '@/components/case/list/main/molecules/CaseListLoginBanner.vue';
import CaseListNoCategoryContentBanner from '@/components/case/list/main/molecules/CaseListNoCategoryContentBanner.vue';
import CaseListNoRelationContentBanner from '@/components/case/list/main/molecules/CaseListNoRelationContentBanner.vue';
import CaseListBannerSection from '@/components/case/list/main/organisms/CaseListBannerSection.vue';
import CaseListEID118Section from '@/components/case/list/main/organisms/CaseListEID118Section.vue';
import CaseListToAnalyticsNativeAdCard from '@/components/case/timeline/molecules/CaseListToAnalyticsNativeAdCard.vue';
import CommunityCard from '@/components/case/timeline/molecules/CommunityCard.vue';
import LoadingSpinner from '@/components/common/atoms/LoadingSpinner.vue';
import CustomNuxtLink from '@/components/CustomNuxtLink.vue';
import PullToRefresh from '@/components/pull-to/PullToRefresh.vue';
import CommunityCardSkeleton from '@/components/skeleton/CommunityCardSkeleton.vue';

import appCommandMixin from '@/mixins/appCommandMixin';
import caseMixin from '@/mixins/caseMixin';
import communityGtmTriggerMixin from '@/mixins/gtm/communityGtmTriggerMixin';
import gtmTriggerMixin from '@/mixins/gtmTriggerMixin';
import layoutMixin from '@/mixins/layoutMixin';
import stateForAppMixin from '@/mixins/stateForAppMixin';
import userInfoMixin from '@/mixins/userInfoMixin';

export default {
	name: 'CaseListCardSection',
	mixins: [
		caseMixin,
		gtmTriggerMixin,
		layoutMixin,
		userInfoMixin,
		stateForAppMixin,
		appCommandMixin,
		communityGtmTriggerMixin,
	],
	async fetch() {
		await this.fetchListData();
	},
	data() {
		return {
			adIndex: 1,
			showRepliesCount: 2,
			startRecentIndex: null,
			isKeyWordEmpty: false,
		};
	},
	computed: {
		...mapGetters(['isUser']),
		...mapGettersOfUserMeta([GET_IS_SHOW_USER_META_MODAL]),
		...mapGettersOfCase([
			CASE_LIST,
			IS_FINISH_RELATION_CONTENT,
			CASE_LIST_INFO,
			CASE_PAGE,
			CASE_LIMIT,
			CASE_SORTING,
			INFINITE_ID,
			IS_SORT_LATEST,
			IS_CAREER_CHANGE,
			IS_EMPTY_RECOMMENDED_LIST,
			CASE_GTM_CATEGORY,
			GET_IS_FETCHING_CASE_LIST,
			QUESTION_TYPE_OPTIONS,
			GET_IS_AUTHORITY,
			IS_JOB_SEEKING,
			CASE_CATEGORY_JOB,
		]),
		...mapGettersOfLayout([SUB_HEADER_INDEX]),
		isShowLoginBanner() {
			// 로그인 배너: 게스트면서 추천순 일 때
			return !this.isUser && !this[IS_SORT_LATEST];
		},
		isCategoryNoResult() {
			// 아직 작성된 콘텐츠가 없음
			return this[IS_SORT_LATEST] && this[CASE_LIST].length === 0;
		},
		cardMarginClass() {
			return this.IS_MOBILE ? 'mb-12' : 'mb-20';
		},
	},
	watch: {
		[GET_IS_SHOW_USER_META_MODAL](newValue) {
			// 맞춤설정 끝나면 리스트 가져오기
			if (!newValue) {
				this[SET_IS_FETCHING_CASE_LIST](true);
				this.$_initCase();
			}
		},
		'$route.query.jobs'() {
			this.$_initCase();
			this.fetchListData();
		},
		'$route.query.feed'() {
			this.handleCaseListGtm();
		},
	},
	mounted() {
		this.handleCaseListGtm();
	},
	methods: {
		...mapMutationsOfCase([
			SET_CASE_LIST,
			SET_CASE_LIST_INFO,
			SET_IS_EMPTY_RECOMMENDED_LIST,
			SET_IS_FETCHING_CASE_LIST,
			SET_CASE_CATEGORY_JOB,
		]),
		...mapActionsOfCase([LOAD_CASE_LIST]),
		refresh(loaded) {
			this.$_initCase();
			this.fetchListData();
			if (loaded) {
				loaded('done');
			}
		},
		async infiniteHandler($state) {
			try {
				const { isFinished } = await this.fetchListData();
				if (isFinished) {
					return $state.complete();
				}
				return $state.loaded();
			} catch {
				return $state.complete();
			}
		},
		handleCaseListGtm() {
			this.handleTriggerGTMOnboardCaseList();
		},
		async handleTriggerGTMOnboardCaseList() {
			try {
				const {
					data: { b2b_user, contract_no },
				} = await this.$api.profile.getValidInfo();
				this.$_onboardCaseList({
					user_no: this.userNo,
					user_type: this.userType,
					sort_type: this[CASE_SORTING],
					page_depth: this[CASE_PAGE],
					category: this.isRecommendation ? '추천피드' : 'Q&A',
					b2b_user,
					contract_no,
					authority: this[GET_IS_AUTHORITY] ? 'Y' : 'N',
					job_filter: this[CASE_CATEGORY_JOB],
				});
			} catch (error) {
				console.error('handleTriggerGTMOnboardCaseList error : ', error);
			}
		},
		async fetchListData() {
			const params = {
				page: this[CASE_PAGE],
				perPage: this[CASE_LIMIT],
				job: this.$route.query.jobs,
			};

			this[SET_CASE_LIST_INFO]({
				page: this[CASE_PAGE] + 1,
			});

			try {
				const {
					data: { data, meta },
				} = await this[LOAD_CASE_LIST](params);

				const { total, current_page: currentPage, last_page: lastPage } = meta;

				const hasData = total > 0;
				if (!hasData) {
					this.setEmptyRecommendedList();
					return {
						isFinished: true,
					};
				}
				this[SET_CASE_LIST_INFO]({ count: total });
				this[SET_CASE_LIST]([...this[CASE_LIST], ...data]);
				return {
					isFinished: currentPage === lastPage,
				};
			} catch (error) {
				this[SET_CASE_LIST_INFO]({ count: 0 });
				this.setEmptyRecommendedList();
				return {
					isFinished: true,
				};
			} finally {
				this[SET_IS_FETCHING_CASE_LIST](false);
			}
		},
		setEmptyRecommendedList() {
			if (!this[IS_SORT_LATEST]) {
				// 추천사례 없음
				this[SET_IS_EMPTY_RECOMMENDED_LIST](true);
			}
		},
		// 안드로이드 웹뷰에서 2번씩 호출돼서 debounce 적용
		goReadCase: debounce(function (item) {
			this.$_for_app_setCaseQuestion({
				title: item.other_inf,
				description: item.question,
				createdAt: item.time,
				categoryGroup: item.category_group,
				category: item.category,
				company: item.search_c,
				job: item.search_j,
			});
			this.clickCaseCardGTM();
			this.$_goReadCase(item);
		}, 100),
		clickCaseCardGTM() {
			this.$_clickCaseCard({
				...this.$_userInfoForGtm,
				category: this.isRecommendation ? '추천피드' : this[CASE_GTM_CATEGORY],
				authority: this[GET_IS_AUTHORITY] ? 'Y' : 'N',
				where: GTM_COMMUNITY_WHERE.ON_BOARD_CASE_LIST,
				job_filter: this[CASE_CATEGORY_JOB],
			});
		},

		showCaseListToAnalyticsNativeAdCard(index) {
			return this.isUser && this.IS_JOB_SEEKING && index === 3;
		},
		removeBaseUrl,
	},
	components: {
		CaseListToAnalyticsNativeAdCard,
		CaseListEID118Section,
		PullToRefresh,
		CommunityCard,
		CommunityCardSkeleton,
		CustomNuxtLink,
		LoadingSpinner,
		CaseListBannerSection,
		FinishRecommendedCaseBanner,
		CaseListNoCategoryContentBanner,
		CaseListLoginBanner,
		CaseListNoRelationContentBanner,
	},
};
