import { mapGetters } from 'vuex';

import { isEmptyData } from '@/utils/utils';

import {
	GET_AD_LIMIT,
	GET_AD_LIST,
	GET_STACK_TIMELINE_AD_LIST,
	GET_TIMELINE_AD_INDEX_LIST,
	mapGettersOfAdvertisement,
	SET_AD_LIST,
	SET_AD_TYPE,
	SET_AD_LIMIT,
	SET_TIMELINE_AD_INDEX_LIST,
	SET_STACK_TIMELINE_AD_LIST,
	mapMutationsOfAdvertisement,
	LOAD_AD_LIST,
	mapActionsOfAdvertisement,
} from '@/store/advertisement';
import { CASE_LIST, mapGettersOfCase, SET_CASE_LIST, mapMutationsOfCase } from '@/store/case';
import {
	GET_POSTS,
	SET_POSTS,
	mapGettersOfCommunityTimeline,
	mapMutationsOfCommunityTimeline,
} from '@/store/community/timeline';

import layoutMixin from '@/mixins/layoutMixin';
import routeMixin from '@/mixins/routeMixin';

const advertisementExposeMixin = {
	mixins: [layoutMixin, routeMixin],
	data() {
		return {};
	},
	computed: {
		...mapGetters(['isUser']),
		...mapGettersOfCommunityTimeline([GET_POSTS]),
		...mapGettersOfCase([CASE_LIST]),
		...mapGettersOfAdvertisement([
			GET_AD_LIMIT,
			GET_AD_LIST,
			GET_STACK_TIMELINE_AD_LIST,
			GET_TIMELINE_AD_INDEX_LIST,
		]),
	},
	methods: {
		...mapMutationsOfCommunityTimeline([SET_POSTS]),
		...mapMutationsOfCase([SET_CASE_LIST]),
		...mapMutationsOfAdvertisement([
			SET_AD_TYPE,
			SET_AD_LIMIT,
			SET_STACK_TIMELINE_AD_LIST,
			SET_AD_LIST,
			SET_TIMELINE_AD_INDEX_LIST,
		]),
		...mapActionsOfAdvertisement([LOAD_AD_LIST]),
		$_resetAdList() {
			this[SET_AD_LIST]([]);
		},
		$_resetStackTimelineAdList() {
			this[SET_STACK_TIMELINE_AD_LIST]([]);
		},
		$_advertisementFindImage(media, kind) {
			/**
			 * kind에 맞게 media return
			 * @param kind: pc, mobile, logo
			 * return 이미지 url
			 */
			if (!media) {
				return false;
			}
			const mediaType = kind || 'logo';
			const item = media.find(item => item.kind === mediaType);
			return item ? item.path : null;
		},
		$_advertisementGoToUrl(url) {
			if (!url) {
				return;
			}
			if (url.includes('https://comento.kr')) {
				this.$_routeMixin_href(url);
			} else {
				this.$_routeMixin_open_page(url);
			}
		},
		async $_addTimelineAdList() {
			/* 노출되었던 광고 리스트 저장 */
			this[SET_AD_TYPE]('home_native_image');
			const { data } = await this.$axios.get(`/api/advertisements`, {
				params: { type: 'home_native_image', sort: 'random', limit: 4 },
			});
			this[SET_STACK_TIMELINE_AD_LIST]([...this[GET_STACK_TIMELINE_AD_LIST], ...data]);
			this[SET_AD_LIST](data);
		},
		async $_loadTimelineAdList(newList) {
			/* 광고와 콘텐츠 저장해서 totalList값으로 return 하기 */
			await this.$_addTimelineAdList();
			const listWithAd = newList;
			let adIndex = 0;
			for (let i = 0; i < listWithAd.length; i++) {
				if (this.$_showTimelineAD(i) && !isEmptyData(this[GET_AD_LIST][adIndex])) {
					const ad = {
						timelineType: 'ad',
						...this[GET_AD_LIST][adIndex],
					};
					listWithAd.splice(i, 0, ad);
					adIndex += 1;
				} else {
					listWithAd[i].timelineType = 'event';
				}
			}

			// this.isUser
			// ? this[SET_CASE_LIST]([...this[CASE_LIST], ...listWithAd])
			this[SET_POSTS]([...this[GET_POSTS], ...listWithAd]);
		},
		$_showTimelineAD(index) {
			return index % 5 === 1;
		},
	},
};

export default advertisementExposeMixin;
